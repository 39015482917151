.App {
  text-align: center;
  background-image: url("img/sep10-22.png");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("img/sep10-22.png"); */
  /* background-position: center top;  */
 
  
}
.colorImage{
  mix-blend-mode: difference;
 
  margin:auto;
}
.imgContainer{
  mix-blend-mode: "difference";
 width:90%;
 
 display:flex;
 justify-content:center;
 margin:auto
}
.bg{
  height: 820vh;
}
 .top{
  margin-top: 15vh;
  margin-bottom: 15vh;
}
p{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  mix-blend-mode: "difference";
  margin-top: 15vh;
  margin-bottom:15vh;
  justify-self: center;
}
.ifram{
  width: 90%;
  margin-bottom:5vh;
  margin:auto;
}
canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 100vh;
  max-width: 100vw;
  transform: translate(-50%, -50%);
}
.bottom{
  margin-top: 15vh;
  margin-bottom:10vh;
}
.sigContainer{
  width: 50vw; 
 
  margin:auto;
}
.sigContainer img{
  width: 100%;
  margin: auto;
  margin-bottom:10vh;
}
.logoContainer{
  width: 80vw; 
  margin: auto;
  position:"absolute";
  bottom:"0"; 
  z-index: "30";
  
}
.logoContainer img{
 
  mix-blend-mode: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linksContainer{
  width: 100%; 
  display:flex; 
  justify-items: flex-start;
  flex-direction: column;
  justify-content:center;
  margin-top: 10vh;
  margin-bottom:10vh;
}

.musicLink{

  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  width:60%;
  text-align: center;
  padding: 10px 6px 10px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
  margin:auto;
  margin-top: 15px;
  border-radius: 10px;
}
.musicLink:hover{
  background-color: #CCCCCC
}